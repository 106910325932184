<template>
  <div class="login-page">
    <div class="login-box" :style="{height: isMobile ? '300px' : '362px'}">
      <div class="title">{{sysName}}在线运营管理平台</div>
      <el-form ref="form" :model="saveData" :rules="rules">
        <el-form-item prop="username">
          <el-input
            class="input"
            placeholder="请输入用户名"
            prefix-icon="iconfont icon-sys-yonghuming"
            v-model="saveData.username"
            autocomplete="off"
            @change="handleUsernameChange"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="请输入密码"
            prefix-icon="iconfont icon-sys-ziyuanxhdpi"
            v-model="saveData.password"
            type="password"
            autocomplete="new-password"
            @change="handlePasswordChange"
          >
          </el-input>
        </el-form-item>
        <el-form-item v-if="!isMobile">
          <JcRange
            status=""
            :iscate="iscate"
            @success-fun="handleSuccessFun"
          ></JcRange>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loginLoading" class="login-btn" @click="handleGoToMain"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import JcRange from "@/components/JcRange";
import { apiLogin, apiLoginCode, apiApiDataSendServe } from "@/api/index";
import { encrypt } from "@/utils/rsaEncrypt";
import { isMobile } from '@/utils/tool'
export default {
  data() {
    return {
      sysName: process.env.VUE_APP_SYS_NAME,
      iscate: false,
      loginLoading: false,
      saveData: {
        username: "",
        password: "",
        /* username: "李五",
        password: "123456", */
        /* username: "赵三1兼职1",
        password: "123456", */
        code: "",
        encode: "",
      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "change" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "change" },
        ],
      },
      isMobile: isMobile(),
    };
  },
  components: {
    JcRange,
  },
  beforeRouteEnter: (to, from, next) => {
    //console.log("to: ", to)
    //console.log("from: ", from)
    //console.log("next: ", next)
    next()
  },
  computed: {},
  created() {
    //console.log("this.$router: ", this.$router)
    //console.log("this.$route: ", this.$route)
  },
  mounted() {
    console.log("process.env.VUE_APP_VERSION: ", process.env.VUE_APP_VERSION)
  },
  methods: {
    /* 处理登录 */
    handleGoToMain() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (!this.isMobile && !this.iscate) {
            this.msgWarn("请先滑动解锁");
            return;
          }
          if(this.loginLoading){
            return
          }
          this.loginLoading = true
          try {
            apiApiDataSendServe({
              type: "before-login",
              params: JSON.stringify(this.saveData)
            })
            const code = await apiLoginCode();
            this.saveData.code = code;
            this.saveData.encode = encrypt(code);
            // useCallPlatform 1 点控云  2 优音云
            let { authorization, authorities, menusList, user: {
              id, name, username, dkyAgentAccount, dkyAgentPassword, dkyDeviceAccount, dkyDevicePassword, dkyOrganId, photo, queueNumber, dkyAgentType, orgId, parentSalesId, highSeasNum, yyyAgentAccount, yyyAgentPassword, useCallPlatform, parentSalesDkyDeviceAccount, parentSalesYyyAgentAccount, parentSalesUseCallPlatform, sqAgentAccount, lxyAgentAccount, lxyAgentPassword, qcyAgentAccount, qcyAgentPassword
            },  roleSigns } =
              await apiLogin(this.saveData);
            apiApiDataSendServe({
              type: "login-success",
              resData: JSON.stringify({id, authorization})
            })
            if(useCallPlatform == null){
              useCallPlatform = 0
            }
            let saleType = ''  // 1 销售专员  // 2 兼职销售
            if(roleSigns.includes('sales')){
              saleType = 1
            }else if(roleSigns.includes("partTimeSales")){
              saleType = 2
            }
            // 角色是否包括销售总监判断
            if(roleSigns.includes('salesMajordomo')){
              sessionStorage.setItem("salesMajordomo", 1)
            }else {
              sessionStorage.removeItem("salesMajordomo")
            }
            if(roleSigns && roleSigns.includes("salesImporter")){
              sessionStorage.setItem("salesImporter", 1)
            }else{
              sessionStorage.removeItem("salesImporter")
            }
            sessionStorage.setItem("highSeasNum", highSeasNum)
            sessionStorage.setItem("parentSalesId", parentSalesId)
            sessionStorage.setItem("Authorization", authorization);
            sessionStorage.setItem("userId", id);
            sessionStorage.setItem("menusList", JSON.stringify(menusList));
            sessionStorage.setItem("username", username);
            sessionStorage.setItem("name", name);
            sessionStorage.setItem('photo', photo)
            sessionStorage.setItem("authorities", JSON.stringify(authorities ? authorities : {}));
            sessionStorage.setItem("dkyAgentType", dkyAgentType)
            sessionStorage.setItem("orgId", orgId)
            sessionStorage.setItem('saleType', saleType)
            sessionStorage.setItem('dkyAgentAccount', dkyAgentAccount ? dkyAgentAccount : '')
            sessionStorage.setItem('dkyAgentPassword', dkyAgentPassword ? dkyAgentPassword : '')
            sessionStorage.setItem('dkyDeviceAccount', dkyDeviceAccount ? dkyDeviceAccount : '')
            sessionStorage.setItem('dkyDevicePassword', dkyDevicePassword ? dkyDevicePassword : '')
            sessionStorage.setItem('dkyOrganId', dkyOrganId ? dkyOrganId : '')
            sessionStorage.setItem('queueNumber', queueNumber ? queueNumber : "")
            sessionStorage.setItem("yyyAgentAccount", yyyAgentAccount ? yyyAgentAccount : '')
            sessionStorage.setItem("yyyAgentPassword", yyyAgentPassword ? yyyAgentPassword : '')
            sessionStorage.setItem("useCallPlatform", useCallPlatform ? useCallPlatform : 0)
            sessionStorage.setItem("parentSalesDkyDeviceAccount", parentSalesDkyDeviceAccount ? parentSalesDkyDeviceAccount : "")
            sessionStorage.setItem("parentSalesYyyAgentAccount", parentSalesYyyAgentAccount ? parentSalesYyyAgentAccount : "")
            sessionStorage.setItem("parentSalesUseCallPlatform", parentSalesUseCallPlatform ? parentSalesUseCallPlatform : "")
            sessionStorage.setItem("yyyParentAgentNum", parentSalesYyyAgentAccount ? parentSalesYyyAgentAccount.split("@")[0] : "")
            sessionStorage.setItem("sqAgentAccount", sqAgentAccount ? sqAgentAccount : "")
            sessionStorage.setItem("lxyAgentAccount", lxyAgentAccount ? lxyAgentAccount : "")
            sessionStorage.setItem("lxyAgentPassword", lxyAgentPassword ? lxyAgentPassword : "")
            sessionStorage.setItem("qcyAgentAccount", qcyAgentAccount ? qcyAgentAccount : "")
            sessionStorage.setItem("qcyAgentPassword", qcyAgentPassword ? qcyAgentPassword : "")
            this.loginLoading = false
            if(saleType == 1 || saleType == 2){
              sessionStorage.removeItem('_lastHandBusyType')
            }
            this.$router.push({
              path: "/welcome",
            });
          } catch (err) {
            this.iscate = false
            this.loginLoading = false
            let msg = err.msg ? err.msg : "登录发生异常！";
            console.error("login err: ", err);
            this.msgError(msg);
          }
        } else {
          this.iscate = false
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSuccessFun() {
      this.iscate = true;
    },
    handleUsernameChange() {
      this.iscate = false
    },
    handlePasswordChange() {
      this.iscate = false
    },
  },
};
</script>
<style lang='scss' scoped>
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../assets/image/login-bg.jpg");
  background-size: cover;
  .login-box {
    width: 400px;
    height: 362px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px 20px;
    .title {
      line-height: 60px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
    /deep/ {
      .el-form-item__content {
        line-height: 46px;
      }
      .el-input__inner {
        height: 46px;
        line-height: 46px;
        padding: 0 15px 0 36px;
      }
      .el-input__prefix{
        margin-left: 6px;
      }
      .el-input__icon {
        line-height: 46px;
      }
    }
    .input {
      height: 46px;
    }
    .login-btn {
      height: 46px;
      width: 100%;
      font-size: 18px;
    }
  }
}
</style>