import Vue from "vue";
import VueRouter from "vue-router";
import login from "@/views/login.vue";
import notFound from '@/views/404.vue'
/* import home from '@/views/home.vue'
import homeType2 from '@/views/homeType2.vue' */
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })

Vue.use(VueRouter);

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => { })
}

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
    redirect: '/login',
    meta: {
      title: '登录',
      breadList: ['登录'],
    },
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: '登录',
      breadList: ['登录'],
    },
  },
  {
    path: "/seeLive",
    name: "seeLive",
    component: () =>
      import(/* webpackChunkName: "seeLive" */ "../views/seeLive.vue"),
    meta: {
      title: '课堂旁听',
      breadList: ['课堂旁听'],
    },
  },
  {
    path: "/seeLivev2",
    name: "seeLivev2",
    component: () =>
      import(/* webpackChunkName: "seeLivev2" */ "../views/seeLivev2.vue"),
    meta: {
      title: '课堂旁听',
      breadList: ['课堂旁听'],
    },
  },
  {
    path: "/otherTest",
    name: "otherTest",
    component: () =>
      import(/* webpackChunkName: "otherTest" */ "../views/otherTest.vue"),
    meta: {
      title: '测试页面',
      breadList: ['测试页面'],
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => {
      const useCallPlatform = sessionStorage.getItem("useCallPlatform")
      // useCallPlatform 1 点控云 2 优音云 3 数企  4 连连
      if(useCallPlatform == 2){
        console.log("useCallPlatform 2")
        return import(/* webpackChunkName: "homeType2" */ "../views/homeType2.vue")
      } else if(useCallPlatform == 3) {
        console.log("useCallPlatform 3")
        return import(/* webpackChunkName: "homeType3" */ "../views/homeType3.vue")
      } else if(useCallPlatform == 4){
        console.log("useCallPlatform 4")
        return import(/* webpackChunkName: "homeType4" */ "../views/homeType4.vue")
      }else if(useCallPlatform == 5){
        console.log("useCallPlatform 5")
        return import(/* webpackChunkName: "homeType5" */ "../views/homeType5.vue")
      }else if(useCallPlatform == 1){
        console.log("useCallPlatform 1")
        return import(/* webpackChunkName: "home" */ "../views/home.vue")
      } else {
        console.log("useCallPlatform 0")
        return import(/* webpackChunkName: "homeType3" */ "../views/homeType3.vue")
      }
    },
      
    meta: {
      title: '首页',
      breadList: ['首页'],
    },
    children: [
      {
        path: "/welcome",
        name: "welcome",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "welcome" */ "../views/welcome.vue"),
        meta: {
          title: '首页',
          breadList: ['首页'],
        },
      },
      /* 更新记录 */
      {
        path: "/updateLogs",
        name: "updateLogs",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "updateLogs" */ "../views/updateLogs.vue"),
        meta: {
          title: '更新记录',
          breadList: ['更新记录'],
        },
      },
      /* 系统 */
      {
        path: "/sysManage/menuManage",
        name: "menuManage",
        component: () =>
          import(/* webpackChunkName: "menuManage" */ "../views/sysManage/menuManage.vue"),
        meta: {
          title: '菜单管理',
          breadList: ['系统管理', '菜单管理'],
        },
      },
      {
        path: "/sysManage/roleManage",
        name: "roleManage",
        component: () =>
          import(/* webpackChunkName: "roleManage" */ "../views/sysManage/roleManage.vue"),
        meta: {
          title: '角色管理',
          breadList: ['系统管理', '角色管理'],
        },
      },
      {
        path: "/sysManage/userManage",
        name: "userManage",
        component: () =>
          import(/* webpackChunkName: "userManage" */ "../views/sysManage/userManage.vue"),
        meta: {
          title: '用户管理',
          breadList: ['系统管理', '用户管理'],
        },
      },
      {
        path: "/sysManage/areaManage",
        name: "areaManage",
        component: () =>
          import(/* webpackChunkName: "areaManage" */ "../views/sysManage/areaManage.vue"),
        meta: {
          title: '行政区域',
          breadList: ['系统管理', '区域管理'],
        },
      },
      {
        path: "/sysManage/publicSourceManage",
        name: "publicSourceManage",
        component: () =>
          import(/* webpackChunkName: "publicSourceManage" */ "../views/sysManage/publicSourceManage.vue"),
        meta: {
          title: '公共资源库',
          breadList: ['系统管理', '公共资源库'],
        },
      },
      {
        path: "/sysManage/macroManage",
        name: "macroManage",
        component: () =>
          import(/* webpackChunkName: "macroManage" */ "../views/sysManage/macroManage.vue"),
        meta: {
          title: '通用字典',
          breadList: ['系统管理', '通用字典'],
        },
      },
      {
        path: "/sysManage/organManage",
        name: "organManage",
        component: () =>
          import(/* webpackChunkName: "organManage" */ "../views/sysManage/organManage.vue"),
        meta: {
          title: '机构管理',
          breadList: ['系统管理', '机构管理'],
        },
      },
      /* 讲师 */
      {
        path: "/teacher/teacherManage/teacherList",
        name: "teacherManage_teacherList",
        component: () =>
          import(/* webpackChunkName: "teacherManage_teacherList" */ "../views/teacher/teacherManage/teacherList.vue"),
        meta: {
          title: '讲师列表',
          breadList: ['讲师', '讲师管理', '讲师列表'],
        },
      },
      {
        path: "/teacher/teacherAudit/teacherList",
        name: "teacherAudit_teacherList",
        component: () =>
          import(/* webpackChunkName: "teacherAudit_teacherList" */ "../views/teacher/teacherAudit/teacherList.vue"),
        meta: {
          title: '讲师列表',
          breadList: ['讲师', '讲师审核', '讲师列表'],
        },
      },
      {
        path: "/teacher/teacherAudit/adviserList",
        name: "teacherAudit_adviserList",
        component: () =>
          import(/* webpackChunkName: "teacherAudit_adviserList" */ "../views/teacher/teacherAudit/adviserList.vue"),
        meta: {
          title: '讲师顾问',
          breadList: ['讲师', '讲师审核', '讲师顾问'],
        },
      },
      {
        path: "/teacher/teacherAudit/teacherPreview",
        name: "teacherAudit_teacherPreview",
        component: () =>
          import(/* webpackChunkName: "teacherAudit_teacherPreview" */ "../views/teacher/teacherAudit/teacherPreview.vue"),
        meta: {
          title: '讲师概览',
          breadList: ['讲师', '讲师审核', '讲师概览'],
        },
      },
      /* 销售 */
      /* 销售专员 */
      {
        path: "/sales/saleStaff/dayPreview",
        name: "saleStaff_dayPreview",
        component: () =>
          import(/* webpackChunkName: "saleStaff_dayPreview" */ "../views/sales/saleStaff/dayPreview.vue"),
        meta: {
          title: '每日看板',
          breadList: ['销售', '销售专员', '每日看板'],
        },
      },
      {
        path: "/sales/saleStaff/handCallList",
        name: "saleStaff_handCallList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_handCallList" */ "../views/sales/saleStaff/handCallList.vue"),
        meta: {
          title: '手拨列表',
          breadList: ['销售', '销售专员', '手拨列表'],
        },
      },
      {
        path: "/sales/saleStaff/callHistoryList",
        name: "saleStaff_callHistoryList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_callHistoryList" */ "../views/sales/saleStaff/callHistoryList.vue"),
        meta: {
          title: '拨打记录',
          breadList: ['销售', '销售专员', '拨打记录'],
        },
      },
      {
        path: "/sales/saleStaff/parttimeCallHistoryList",
        name: "saleStaff_parttimeCallHistoryList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_parttimeCallHistoryList" */ "../views/sales/saleStaff/parttimeCallHistoryList.vue"),
        meta: {
          title: '兼职拨打记录',
          breadList: ['销售', '销售专员', '兼职拨打记录'],
        },
      },
      {
        path: "/sales/saleStaff/testPage",
        name: "saleStaff_testPage",
        component: () =>
          import(/* webpackChunkName: "saleStaff_testPage" */ "../views/sales/saleStaff/testPage.vue"),
        meta: {
          title: '测试页面',
          breadList: ['销售', '销售专员', '测试页面'],
        },
      },
      {
        path: "/sales/saleStaff/userList",
        name: "saleStaff_userList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_userList" */ "../views/sales/saleStaff/userList.vue"),
        meta: {
          title: '签约学生',
          breadList: ['销售', '销售专员', '签约学生'],
        },
      },
      {
        path: "/sales/saleStaff/parttimeIntention",
        name: "parttimeIntention",
        component: () =>
          import(/* webpackChunkName: "parttimeIntention" */ "../views/sales/saleStaff/parttimeIntention.vue"),
        meta: {
          title: '兼职意向',
          breadList: ['销售', '销售专员', '兼职意向'],
        },
      },
      {
        path: "/sales/saleStaff/parttimeStatistics",
        name: "parttimeStatistics",
        component: () =>
          import(/* webpackChunkName: "parttimeStatistics" */ "../views/sales/saleStaff/parttimeStatistics.vue"),
        meta: {
          title: '兼职统计',
          breadList: ['销售', '销售专员', '兼职统计'],
        },
      },
      {
        path: "/sales/saleStaff/orderList",
        name: "saleStaff_orderList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_orderList" */ "../views/sales/saleStaff/orderList.vue"),
        meta: {
          title: '订单列表',
          breadList: ['销售', '销售专员', '订单列表'],
        },
      },
      {
        path: "/sales/saleStaff/intentionList",
        name: "saleStaff_intentionList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_intentionList" */ "../views/sales/saleStaff/intentionList.vue"),
        meta: {
          title: '意向列表',
          breadList: ['销售', '销售专员', '意向列表'],
        },
      },
      {
        path: "/sales/saleStaff/parttimeList",
        name: "saleStaff_parttimeList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_parttimeList" */ "../views/sales/saleStaff/parttimeList.vue"),
        meta: {
          title: '兼职列表',
          breadList: ['销售', '销售专员', '兼职列表'],
        },
      },
      {
        path: "/sales/saleStaff/openSeaList",
        name: "saleStaff_openSeaList",
        component: () =>
          import(/* webpackChunkName: "saleStaff_openSeaList" */ "../views/sales/saleStaff/openSeaList.vue"),
        meta: {
          title: '公海',
          breadList: ['销售', '销售专员', '公海'],
        },
      },
      /* 销售主管 */
      {
        path: "/sales/saleLeader/dayPreview",
        name: "saleLeader_dayPreview",
        component: () =>
          import(/* webpackChunkName: "saleLeader_dayPreview" */ "../views/sales/saleLeader/dayPreview.vue"),
        meta: {
          title: '每日看板',
          breadList: ['销售', '销售主管', '每日看板'],
        },
      },
      /* 销售主管-意向概览(意向列表) */
      {
        path: "/sales/saleLeader/intentionPreview",
        name: "saleLeader_intentionPreview",
        component: () =>
          import(/* webpackChunkName: "saleLeader_intentionPreview" */ "../views/sales/saleLeader/intentionPreview.vue"),
        meta: {
          title: '意向列表',
          breadList: ['销售', '销售主管', '意向列表'],
        },
      },
      {
        path: "/sales/saleLeader/intentionStatistics",
        name: "intentionStatistics",
        component: () =>
          import(/* webpackChunkName: "intentionStatistics" */ "../views/sales/saleLeader/intentionStatistics.vue"),
        meta: {
          title: '意向列表',
          breadList: ['销售', '销售主管', '意向列表'],
        },
      },
      {
        path: "/sales/saleLeader/orderStatistics",
        name: "orderStatistics",
        component: () =>
          import(/* webpackChunkName: "orderStatistics" */ "../views/sales/saleLeader/orderStatistics.vue"),
        meta: {
          title: '签约列表',
          breadList: ['销售', '销售主管', '签约列表'],
        },
      },
      {
        path: "/sales/saleLeader/saleStatistics",
        name: "saleStatistics",
        component: () =>
          import(/* webpackChunkName: "saleStatistics" */ "../views/sales/saleLeader/saleStatistics.vue"),
        meta: {
          title: '销售统计',
          breadList: ['销售', '销售主管', '销售统计'],
        },
      },
      {
        path: "/sales/saleLeader/weekReport",
        name: "saleLeader_dweekReport",
        component: () =>
          import(/* webpackChunkName: "saleLeader_weekReport" */ "../views/sales/saleLeader/weekReport.vue"),
        meta: {
          title: '本周报表',
          breadList: ['销售', '销售主管', '本周报表'],
        },
      },
      {
        path: "/sales/saleLeader/negotiationOrderList",
        name: "saleLeader_negotiationOrderList",
        component: () =>
          import(/* webpackChunkName: "saleLeader_negotiationOrderList" */ "../views/sales/saleLeader/negotiationOrderList.vue"),
        meta: {
          title: '谈单分析',
          breadList: ['销售', '销售主管', '谈单分析'],
        },
      },
      /* 销售主管 销售总监 外边多了一层 */
      {
        path: "/sales/saleLeader/callHistoryList",
        name: "saleLeader_callHistoryList",
        component: () =>
          import(/* webpackChunkName: "saleLeader_callHistoryList" */ "../views/sales/saleStaff/callHistoryList.vue"),
        meta: {
          title: '拨打记录',
          breadList: ['销售', '销售主管', '拨打记录'],
          //activeMenu: "/sales/saleLeader/callHistoryPreview"
        },
      },
      /* 销售主管 销售总监 多的拨打记录统计 */
      {
        path: "/sales/saleLeader/callHistoryPreview",
        name: "saleLeader_callHistoryPreview",
        component: () =>
          import(/* webpackChunkName: "saleLeader_callHistoryPreview" */ "../views/sales/saleMajordomo/callHistoryPreview.vue"),
        meta: {
          title: '拨打统计',
          breadList: ['销售', '销售主管', '拨打统计'],
        },
      },
      /* 销售总监-每日看板 */
      {
        path: "/sales/saleMajordomo/dayPreview",
        name: "saleMajordomo_dayPreview",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_dayPreview" */ "../views/sales/saleLeader/dayPreview.vue"),
        meta: {
          title: '每日看板',
          breadList: ['销售', '销售总监', '每日看板'],
        },
      },
      /* 销售主管-意向概览(意向列表) */
      {
        path: "/sales/saleMajordomo/intentionPreview",
        name: "saleLeader_intentionPreview",
        component: () =>
          import(/* webpackChunkName: "saleLeader_intentionPreview" */ "../views/sales/saleLeader/intentionPreview.vue"),
        meta: {
          title: '意向列表',
          breadList: ['销售', '销售总监', '意向列表'],
        },
      },
      {
        path: "/sales/saleLeader/salePersonalList",
        name: "saleLeader_salePersonalList",
        component: () =>
          import(/* webpackChunkName: "saleLeader_salePersonalList" */ "../views/sales/dataManage/salePersonalList.vue"),
        meta: {
          title: '销售个人库',
          breadList: ['销售', '销售主管', '销售个人库'],
        },
      },
      /* 我的兼职 */
      {
        path: "/sales/myParttime/userList",
        name: "myParttime_userList",
        component: () =>
          import(/* webpackChunkName: "myParttime_userList" */ "../views/sales/myParttime/userList.vue"),
        meta: {
          title: '外呼列表',
          breadList: ['销售', '我的兼职', '外呼列表'],
        },
      },
      {
        path: "/sales/myParttime/intentionList",
        name: "myParttime_intentionList",
        component: () =>
          import(/* webpackChunkName: "myParttime_intentionList" */ "../views/sales/myParttime/intentionList.vue"),
        meta: {
          title: '意向表',
          breadList: ['销售', '我的兼职', '意向表'],
        },
      },
      {
        path: "/sales/myParttime/handCallList",
        name: "myParttime_handCallList",
        component: () =>
          import(/* webpackChunkName: "myParttime_handCallList" */ "../views/sales/myParttime/handCallList.vue"),
        meta: {
          title: '手拨列表',
          breadList: ['销售', '我的兼职', '手拨列表'],
        },
      },
      {
        path: "/sales/myParttime/callHistoryList",
        name: "myParttime_callHistoryList",
        component: () =>
          import(/* webpackChunkName: "myParttime_callHistoryList" */ "../views/sales/saleStaff/callHistoryList.vue"),
        meta: {
          title: '拨打记录',
          breadList: ['销售', '我的兼职', '拨打记录'],
        },
      },
      /* 数据管理员 */
      /* 通知管理 */
      {
        path: "/sales/dataManage/noticeList",
        name: "noticeList",
        component: () =>
          import(/* webpackChunkName: "noticeList" */ "../views/sales/dataManage/noticeList.vue"),
        meta: {
          title: '通知管理',
          breadList: ['销售', '数据管理员', '通知管理'],
        },
      },
      {
        path: "/sales/dataManage/blackList",
        name: "blackList",
        component: () =>
          import(/* webpackChunkName: "blackList" */ "../views/sales/dataManage/blackList.vue"),
        meta: {
          title: '黑名单',
          breadList: ['销售', '数据管理员', '黑名单'],
        },
      },
      {
        path: "/sales/dataManage/callLogList",
        name: "callLogList",
        component: () =>
          import(/* webpackChunkName: "callLogList" */ "../views/sales/dataManage/callLogList.vue"),
        meta: {
          title: '呼出记录',
          breadList: ['销售', '数据管理员', '呼出记录'],
        },
      },
      {
        path: "/sales/dataManage/callPool",
        name: "callPool",
        component: () =>
          import(/* webpackChunkName: "callPool" */ "../views/sales/dataManage/callPool.vue"),
        meta: {
          title: '总电话池',
          breadList: ['销售', '数据管理员', '总电话池'],
        },
      },
      {
        path: "/sales/dataManage/firstHandCallPool",
        name: "firstHandCallPool",
        component: () =>
          import(/* webpackChunkName: "firstHandCallPool" */ "../views/sales/dataManage/firstHandCallPool.vue"),
        meta: {
          title: '新数据池子',
          breadList: ['销售', '数据管理员', '新数据池子'],
        },
      },
      {
        path: "/sales/dataManage/successHandCallPool",
        name: "successHandCallPool",
        component: () =>
          import(/* webpackChunkName: "successHandCallPool" */ "../views/sales/dataManage/successHandCallPool.vue"),
        meta: {
          title: '真实数据池子',
          breadList: ['销售', '数据管理员', '真实数据池子'],
        },
      },
      {
        path: "/sales/dataManage/noWanderList",
        name: "noWanderList",
        component: () =>
          import(/* webpackChunkName: "noWanderList" */ "../views/sales/dataManage/noWanderList.vue"),
        meta: {
          title: '禁止轮转列表',
          breadList: ['销售', '数据管理员', '禁止轮转列表'],
        },
      },
      {
        path: "/sales/dataManage/upgradationList",
        name: "upgradationList",
        component: () =>
          import(/* webpackChunkName: "upgradationList" */ "../views/sales/dataManage/upgradationList.vue"),
        meta: {
          title: '升级学生列表',
          breadList: ['销售', '数据管理员', '升级学生列表'],
        },
      },
      {
        path: "/sales/dataManage/openSeaList",
        name: "dataManage_openSeaList",
        component: () =>
          import(/* webpackChunkName: "dataManage_openSeaList" */ "../views/sales/saleStaff/openSeaList.vue"),
        meta: {
          title: '公海',
          breadList: ['销售', '数据管理员', '公海'],
        },
      },
      {
        path: "/sales/dataManage/importListStatistics",
        name: "importListStatistics",
        component: () =>
          import(/* webpackChunkName: "importListStatistics" */ "../views/sales/dataManage/importListStatistics.vue"),
        meta: {
          title: '数据统计',
          breadList: ['销售', '数据管理员', '数据统计'],
        },
      },
      {
        path: "/sales/dataManage/importList",
        name: "dataManage_importList",
        component: () =>
          import(/* webpackChunkName: "dataManage_importList" */ "../views/sales/dataManage/importList.vue"),
        meta: {
          title: '数据列表',
          breadList: ['销售', '数据管理员', '数据列表'],
        },
      },
      {
        path: "/sales/dataManage/importList/importDetail",
        name: "importDetail",
        component: () =>
          import(/* webpackChunkName: "importDetail" */ "../views/sales/dataManage/importDetail.vue"),
        meta: {
          title: '数据详情',
          activeMenu: '/sales/dataManage/importList',
          breadList: ['销售', '数据管理员', '数据列表', '数据详情'],
        },
      },
      {
        path: "/sales/dataManage/callTaskList",
        name: "dataManage_callTaskList",
        component: () =>
          import(/* webpackChunkName: "dataManage_callTaskList" */ "../views/sales/dataManage/callTaskList.vue"),
        meta: {
          title: '任务列表',
          breadList: ['销售', '数据管理员', '任务列表'],
        },
      },
      {
        path: "/sales/dataManage/phoneBind",
        name: "dataManage_phoneBind",
        component: () =>
          import(/* webpackChunkName: "dataManage_phoneBind" */ "../views/sales/dataManage/phoneBind.vue"),
        meta: {
          title: '手机号绑定',
          breadList: ['销售', '数据管理员', '手机号绑定'],
        },
      },
      {
        path: "/sales/dataManage/callMonitor",
        name: "dataManage_callMonitor",
        component: () =>
          import(/* webpackChunkName: "dataManage_callMonitor" */ "../views/sales/dataManage/callMonitor.vue"),
        meta: {
          title: '坐席监控',
          breadList: ['销售', '数据管理员', '坐席监控'],
        },
      },
      {
        path: "/sales/dataManage/callAccountManage",
        name: "dataManage_callAccountManage",
        component: () =>
          import(/* webpackChunkName: "dataManage_callAccountManage" */ "../views/sales/dataManage/callAccountManage.vue"),
        meta: {
          title: '坐席分配',
          breadList: ['销售', '数据管理员', '坐席分配'],
        },
      },
      {
        path: "/sales/dataManage/callPlatformSet",
        name: "dataManage_callPlatformSet",
        component: () =>
          import(/* webpackChunkName: "dataManage_callPlatformSet" */ "../views/sales/dataManage/callPlatformSet.vue"),
        meta: {
          title: '电话设置',
          breadList: ['销售', '数据管理员', '电话设置'],
        },
      },
      {
        path: "/sales/dataManage/salePersonalList",
        name: "dataManage_salePersonalList",
        component: () =>
          import(/* webpackChunkName: "dataManage_salePersonalList" */ "../views/sales/dataManage/salePersonalList.vue"),
        meta: {
          title: '销售个人库',
          breadList: ['销售', '数据管理员', '销售个人库'],
        },
      },
      {
        path: "/sales/dataManage/biYeShengList",
        name: "dataManage_biYeShengList",
        component: () =>
          import(/* webpackChunkName: "dataManage_biYeShengList" */ "../views/sales/dataManage/biYeShengList.vue"),
        meta: {
          title: '毕业生列表',
          breadList: ['销售', '数据管理员', '毕业生列表'],
        },
      },
      {
        path: "/sales/dataManage/callTaskList/callTaskDetail",
        name: "callDetail",
        component: () =>
          import(/* webpackChunkName: "callDetail" */ "../views/sales/dataManage/callTaskDetail.vue"),
        meta: {
          title: '任务详情',
          activeMenu: '/sales/dataManage/callTaskList',
          breadList: ['销售', '数据管理员', '任务列表','任务详情'],
        },
      },
      /* 销售总监 */
      {
        path: "/sales/saleMajordomo/orderStatistics",
        name: "major_orderStatistics",
        component: () =>
          import(/* webpackChunkName: "orderStatistics" */ "../views/sales/saleLeader/orderStatistics.vue"),
        meta: {
          title: '签约列表',
          breadList: ['销售', '销售总监', '签约列表'],
        },
      },
      {
        path: "/sales/saleMajordomo/intentionStatistics",
        name: "major_intentionStatistics",
        component: () =>
          import(/* webpackChunkName: "saleSectionIntertion" */ "../views/sales/saleLeader/intentionStatistics.vue"),
        meta: {
          title: '意向列表',
          breadList: ['销售', '销售总监', '意向列表'],
        },
      },
      {
        path: "/sales/saleMajordomo/saleSectionIntertion",
        name: "saleSectionIntertion",
        component: () =>
          import(/* webpackChunkName: "saleSectionIntertion" */ "../views/sales/saleMajordomo/saleSectionIntertion.vue"),
        meta: {
          title: '部门意向',
          breadList: ['销售', '销售总监', '部门意向'],
        },
      },
      {
        path: "/sales/saleMajordomo/saleStaffPerformanceSet",
        name: "saleStaffPerformanceSet",
        component: () =>
          import(/* webpackChunkName: "saleStaffPerformanceSet" */ "../views/sales/saleMajordomo/saleStaffPerformanceSet.vue"),
        meta: {
          title: '员工业绩设置',
          breadList: ['销售', '销售总监', '员工业绩设置'],
        },
      },
      /* 销售主管 销售总监 外边多了一层 */
      {
        path: "/sales/saleMajordomo/callHistoryList",
        name: "saleMajordomo_callHistoryList",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_callHistoryList" */ "../views/sales/saleStaff/callHistoryList.vue"),
        meta: {
          title: '拨打记录',
          breadList: ['销售', '销售总监', '拨打记录'],
          //activeMenu: "/sales/saleMajordomo/callHistoryPreview"
        },
      },
      /* 销售主管 销售总监 多的拨打记录统计 */
      {
        path: "/sales/saleMajordomo/callHistoryPreview",
        name: "saleMajordomo_callHistoryPreview",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_callHistoryList" */ "../views/sales/saleMajordomo/callHistoryPreview.vue"),
        meta: {
          title: '拨打统计',
          breadList: ['销售', '销售总监', '拨打统计'],
        },
      },
      {
        path: "/sales/saleMajordomo/salePersonalList",
        name: "saleMajordomo_salePersonalList",
        component: () =>
          import(/* webpackChunkName: "saleMajordomo_salePersonalList" */ "../views/sales/dataManage/salePersonalList.vue"),
        meta: {
          title: '销售个人库',
          breadList: ['销售', '销售总监', '销售个人库'],
        },
      },
      /* 财务 */
      {
        path: "/finance/dayPreview",
        name: "finance_dayPreview",
        component: () =>
          import(/* webpackChunkName: "finance_dayPreview" */ "../views/finance/dayPreview.vue"),
        meta: {
          title: '每日看板',
          breadList: ['财务', '每日看板'],
        },
      },
      {
        path: "/finance/classConsumeStatistics",
        name: "finance_classConsumeStatistics",
        component: () =>
          import(/* webpackChunkName: "finance_classConsumeStatistics" */ "../views/finance/classConsumeStatistics.vue"),
        meta: {
          title: '课酬课耗统计',
          breadList: ['财务', '课酬课耗统计'],
        },
      },
      {
        path: "/finance/orderList",
        name: "finance_orderList",
        component: () =>
          import(/* webpackChunkName: "finance_orderList" */ "../views/finance/orderList.vue"),
        meta: {
          title: '订单审核',
          breadList: ['财务', '订单审核'],
        },
      },
      {
        path: "/finance/incomeStatistics",
        name: "finance_incomeStatistics",
        component: () =>
          import(/* webpackChunkName: "finance_incomeStatistics" */ "../views/finance/incomeStatistics.vue"),
        meta: {
          title: '收入统计',
          breadList: ['财务', '收入统计'],
        },
      },
      {
        path: "/finance/classhourStatistics",
        name: "finance_classhourStatistics",
        component: () =>
          import(/* webpackChunkName: "finance_classhourStatistics" */ "../views/finance/classhourStatistics.vue"),
        meta: {
          title: '课时统计',
          breadList: ['财务', '课时统计'],
        },
      },
      {
        path: "/finance/refundList",
        name: "finance_refundList",
        component: () =>
          import(/* webpackChunkName: "finance_refundList" */ "../views/finance/refundList.vue"),
        meta: {
          title: '退款明细',
          breadList: ['财务', '退款明细'],
        },
      },
      {
        path: "/finance/teacherWageList",
        name: "finance_teacherWageList",
        component: () =>
          import(/* webpackChunkName: "finance_teacherWageList" */ "../views/finance/teacherWageList.vue"),
        meta: {
          title: '讲师课酬',
          breadList: ['财务', '讲师课酬'],
        },
      },
      {
        path: "/finance/studentClasshour",
        name: "finance_studentClasshour",
        component: () =>
          import(/* webpackChunkName: "finance_studentClasshour" */ "../views/finance/studentClasshour.vue"),
        meta: {
          title: '学员课时',
          breadList: ['财务', '学员课时'],
        },
      },
      {
        path: "/finance/classPayList",
        name: "finance_classPayList",
        component: () =>
          import(/* webpackChunkName: "finance_classPayList" */ "../views/finance/classPayList.vue"),
        meta: {
          title: '课酬设置',
          breadList: ['财务', '课酬设置'],
        },
      },
      {
        path: "/finance/salePerformanceList",
        name: "finance_salePerformanceList",
        component: () =>
          import(/* webpackChunkName: "finance_salePerformanceList" */ "../views/finance/salePerformanceList.vue"),
        meta: {
          title: '销售业绩',
          breadList: ['财务', '销售业绩'],
        },
      },

      {
        path: "/finance/teachBusinessPerformanceList",
        name: "finance_teachBusinessPerformanceList",
        component: () =>
          import(/* webpackChunkName: "finance_teachBusinessPerformanceList" */ "../views/finance/teachBusinessPerformanceList.vue"),
        meta: {
          title: '教务业绩',
          breadList: ['财务', '教务业绩'],
        },
      },
      {
        path: "/finance/studentPackageList",
        name: "finance_studentPackageList",
        component: () =>
          import(/* webpackChunkName: "finance_studentPackageList" */ "../views/finance/studentPackageList.vue"),
        meta: {
          title: '学员套餐',
          breadList: ['财务', '学员套餐'],
        },
      },
      {
        path: "/finance/remainClasshourList",
        name: "finance_remainClasshourList",
        component: () =>
          import(/* webpackChunkName: "finance_remainClasshourList" */ "../views/finance/remainClasshourList.vue"),
        meta: {
          title: '剩余课时',
          breadList: ['财务', '剩余课时'],
        },
      },
      /* 教务 */
      /* 教务专员 */
      {
        path: "/teachBusiness/teachBusinessAttache/dayPreview",
        name: "teachBusinessAttache_dayPreview",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_dayPreview" */ "../views/teachBusiness/teachBusinessAttache/dayPreview.vue"),
        meta: {
          title: '每日看板',
          breadList: ['教务', '教务专员', '每日看板'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/dayPreviewDetail",
        name: "teachBusinessAttache_dayPreviewDetail",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_dayPreviewDetail" */ "../views/teachBusiness/teachBusinessAttache/dayPreviewDetail.vue"),
        meta: {
          title: '每日看板',
          activeMenu: '/teachBusiness/teachBusinessAttache/dayPreview',
          breadList: ['教务', '教务专员', '每日看板', "看板信息"],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/myIncome",
        name: "teachBusinessAttache_myIncome",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_myIncome" */ "../views/teachBusiness/teachBusinessAttache/myIncome.vue"),
        meta: {
          title: '我的收入',
          breadList: ['教务', '教务专员', '我的收入'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/addStudent",
        name: "teachBusinessAttache_addStudent",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_addStudent" */ "../views/teachBusiness/teachBusinessAttache/addStudent.vue"),
        meta: {
          title: '新增学员',
          breadList: ['教务', '教务专员', '新增学员'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/myStudentList",
        name: "teachBusinessAttache_myStudentList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_myStudentList" */ "../views/teachBusiness/teachBusinessAttache/myStudentList.vue"),
        meta: {
          title: '我的学员',
          breadList: ['教务', '教务专员', '我的学员'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/studentSubjectInfo",
        name: "teachBusinessAttache_studentSubjectInfo",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_studentSubjectInfo" */ "../views/teachBusiness/teachBusinessAttache/studentSubjectInfo.vue"),
        meta: {
          title: '学员科目',
          breadList: ['教务', '教务专员', '学员科目'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/courseList",
        name: "teachBusinessAttache_courseList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_courseList" */ "../views/teachBusiness/teachBusinessAttache/courseList.vue"),
        meta: {
          title: '课表管理',
          breadList: ['教务', '教务专员', '课表管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/monitorClass",
        name: "teachBusinessAttache_monitorClass",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_monitorClass" */ "../views/teachBusiness/teachBusinessAttache/monitorClass.vue"),
        meta: {
          title: '监课管理',
          breadList: ['教务', '教务专员', '监课管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/playbackList",
        name: "teachBusinessAttache_playbackList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_playbackList" */ "../views/teachBusiness/teachBusinessAttache/playbackList.vue"),
        meta: {
          title: '回放管理',
          breadList: ['教务', '教务专员', '回放管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/classPayList",
        name: "teachBusinessAttache_classPayList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_classPayList" */ "../views/finance/classPayList.vue"),
        meta: {
          title: '课酬设置',
          breadList: ['教务', '教务专员', '课酬设置'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/teacherListFormal",
        name: "teachBusinessAttache_teacherListFormal",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_teacherListFormal" */ "../views/teachBusiness/teachBusinessAttache/teacherListFormal.vue"),
        meta: {
          title: '教师管理-正式表',
          breadList: ['教务', '教务专员', '教师管理-正式表'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessAttache/teacherListReserve",
        name: "teachBusinessAttache_teacherListReserve",
        component: () =>
          import(/* webpackChunkName: "teachBusinessAttache_teacherListReserve" */ "../views/teachBusiness/teachBusinessAttache/teacherListReserve.vue"),
        meta: {
          title: '教师管理-储备表',
          breadList: ['教务', '教务专员', '教师管理-储备表'],
        },
      },
      /* 教务主管 */
      {
        path: "/teachBusiness/teachBusinessSupervisor/dayPreview",
        name: "teachBusinessSupervisor_dayPreview",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_dayPreview" */ "../views/teachBusiness/teachBusinessAttache/dayPreview.vue"),
        meta: {
          title: '每日看板',
          breadList: ['教务', '教务主管', '每日看板'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/dayPreviewDetail",
        name: "teachBusinessSupervisor_dayPreviewDetail",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_dayPreviewDetail" */ "../views/teachBusiness/teachBusinessAttache/dayPreviewDetail.vue"),
        meta: {
          title: '每日看板',
          activeMenu: '/teachBusiness/teachBusinessSupervisor/dayPreview',
          breadList: ['教务', '教务主员', '每日看板', "看板信息"],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/studentList",
        name: "teachBusinessSupervisor_studentList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_studentList" */ "../views/teachBusiness/teachBusinessSupervisor/studentList.vue"),
        meta: {
          title: '部门学员',
          breadList: ['教务', '教务主管', '部门学员'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/courseList",
        name: "teachBusinessSupervisor_courseList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_courseList" */ "../views/teachBusiness/teachBusinessAttache/courseList.vue"),
        meta: {
          title: '部门课表',
          breadList: ['教务', '教务主管', '部门课表'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/monitorClass",
        name: "teachBusinessSupervisor_monitorClass",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_monitorClass" */ "../views/teachBusiness/teachBusinessAttache/monitorClass.vue"),
        meta: {
          title: '监课管理',
          breadList: ['教务', '教务主管', '监课管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/playbackList",
        name: "teachBusinessSupervisor_playbackList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_playbackList" */ "../views/teachBusiness/teachBusinessAttache/playbackList.vue"),
        meta: {
          title: '回放管理',
          breadList: ['教务', '教务主管', '回放管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/refundAudit",
        name: "teachBusinessSupervisor_refundAudit",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_refundAudit" */ "../views/teachBusiness/teachBusinessSupervisor/refundAudit.vue"),
        meta: {
          title: '退款审核',
          breadList: ['教务', '教务主管', '退款审核'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/teachBusinessList",
        name: "teachBusinessSupervisor_teachBusinessList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_teachBusinessList" */ "../views/teachBusiness/teachBusinessSupervisor/teachBusinessList.vue"),
        meta: {
          title: '人员管理',
          breadList: ['教务', '教务主管', '人员管理'],
        },
      },
      {
        path: "/teachBusiness/teachBusinessSupervisor/performanceList",
        name: "teachBusinessSupervisor_performanceList",
        component: () =>
          import(/* webpackChunkName: "teachBusinessSupervisor_performanceList" */ "../views/teachBusiness/teachBusinessSupervisor/performanceList.vue"),
        meta: {
          title: '部门业绩',
          breadList: ['教务', '教务主管', '部门业绩'],
        },
      },
    ]
  },
  {
    path: '*',
    name: 'notFound',
    component: notFound,
    meta: {
      title: '404'
    }
  }
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})
router.afterEach(to => {
  NProgress.done()
  document.title = to.meta.title
})
export default router;
